export function useHooks() {
    const hooks: any[] = [];

    const addHook = (name: string, hook: () => void) => {
        hooks.push({ name, hook })
    }

    const addHooks = (names: string[], hook: () => void) => {
        names.forEach(name => addHook(name, hook ))
    }

    const invokeHook = (name: string, e?: any) => { 
        return hooks
            .filter(h => h.name == name)
            .map(h => h.hook(e))
    }

    return {
        addHook,
        addHooks,
        invokeHook
    }
}